import api from "../../services/api";
import Logo from "@/assets/Logo-Primary.png";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HiOutlineDocumentPlus } from "react-icons/hi2";

import STATUS_SPAN from "./components/constant";
import { MdEdit } from "react-icons/md";

const Summary = ({ values, setValues, coupleValues, setCoupleValues }) => {
  const [hasAcceptArticle, setHasAcceptArticle] = useState(false);
  const [hasAcceptData, setHasAcceptData] = useState(false);
  const navigate = useNavigate();

  const validateReport = async () => {
    if (!values.is_completed) return toast.error("Votre dossier n'est pas complet.");
    if (values.situation === "COUPLE" && !coupleValues.is_completed) return toast.error("Le dossier de votre conjoint.e n'est pas complet.");
    try {
      toast.success("Votre dossier est parti en validation.");
      const response = await api.put(`/report/${values._id}`, { ...values, status: "PROCESSING" });
      if (!response.ok) throw response;
      setValues(response.data);
      const res = await api.post(`/report/${values._id}/submit`);
      if (!res.ok) throw res;
      if (values.situation === "COUPLE") {
        const coupleRes = await api.put(`/report/${coupleValues._id}`, { ...coupleValues, status: "PROCESSING" });
        console.log(coupleRes);
        if (!coupleRes.ok) throw coupleRes;
        setCoupleValues(coupleRes.data);
        console.log("coupleValues process");
        const coupleResponse = await api.post(`/report/${coupleValues._id}/submit`);
        if (!coupleResponse.ok) throw coupleResponse;
      }
    } catch (error) {
      toast.error("une erreur est survenu");
    }
  };

  const onUpdate = async (report, couple) => {
    try {
      const res = await api.put(`/report/${report._id}`, { ...report });
      if (!res.ok) throw res;
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex justify-between items-center px-24 py-6 bg-white">
        <img className="w-44" alt="logo" src={Logo} />
      </div>
      <div className="w-full flex justify-between items-center px-24 py-8 bg-white">
        <h1 className="text-primary text-4xl font-semibold">Mon dossier locatif</h1>
      </div>
      <div className="w-full grid grid-cols-3 gap-x-4 gap-y-12 px-24 py-12">
        <div className="col-span-2 flex flex-col gap-6 pr-12 border-r">
          <div className="flex gap-4">
            <h2 className="text-2xl font-bold text-primary">Mes informations </h2>

            {STATUS_SPAN[values.status === "INCOMPLETED" ? "INCOMPLETED" : "COMPLETED"]}
          </div>
          <div className="flex flex-col justify-between border gap-2 p-4 rounded-lg bg-white">
            <p className="text-lg font-semibold">
              {values.user_first_name} {values.user_last_name}
            </p>

            <p>
              email : <span className="font-semibold">{values.user_email}</span>
            </p>
            <p>
              numéro de téléphone : <span className="font-semibold">+33 {values.user_phone}</span>
            </p>
            <hr />
            <div className="flex space-x-4">
              <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../tenant", { state: { from: "SUMMARY" } })}>
                <MdEdit />
                <p>Modifier la fiche</p>
              </button>
              <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../tenant/documents", { state: { from: "SUMMARY" } })}>
                <HiOutlineDocumentPlus />
                <p>Ajouter des documents</p>
              </button>
            </div>
          </div>
          <h2 className="text-xl font-bold text-primary">Mon garant</h2>
          {values.has_guarantor && (
            <div className="flex flex-col justify-between border gap-2 p-4 rounded-lg bg-white">
              <div className="flex items-center gap-2">
                <p className="text-lg font-semibold">
                  {values.guarantor_first_name} {values.guarantor_last_name}
                </p>
              </div>

              <p>
                email : <span className="font-semibold">{values.guarantor_email}</span>
              </p>
              <p>
                numéro de téléphone : <span className="font-semibold">+33 {values.guarantor_phone}</span>
              </p>
              <hr />
              <div className="flex space-x-4">
                <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../tenant/guarantor", { state: { from: "SUMMARY" } })}>
                  <MdEdit />
                  <p>Modifier la fiche</p>
                </button>
                <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../tenant/guarantor/documents", { state: { from: "SUMMARY" } })}>
                  <HiOutlineDocumentPlus />
                  <p>Ajouter des documents</p>
                </button>
              </div>
            </div>
          )}
          {!values.has_guarantor && (
            <button
              className="text-blue-600 border border-blue-600 py-2 px-4 rounded-lg"
              onClick={() => {
                const newValues = { ...values, has_guarantor: true };
                setValues({ ...newValues });
                onUpdate(newValues, false);
                navigate("../tenant/guarantor");
              }}
            >
              Ajouter un garant
            </button>
          )}
        </div>
        <div className="bg-green-50 p-6 rounded-lg h-fit">
          <h3 className="font-bold">Avec Doxallia :</h3>
          <ul className="list-disc list-inside">
            <li>Constituez votre dossier locataire une seule fois</li>
            <li>Postulez en ligne et réutilisez votre dossier dans toutes vos démarches</li>
          </ul>
        </div>

        <hr className="col-span-3" />

        {values.situation === "COUPLE" && (
          <>
            <div className="col-span-2 flex flex-col gap-6 pr-12 border-r">
              <div className="flex gap-4">
                <h2 className="text-2xl font-bold text-cyan-600">Les informations de votre conjoint.e</h2>

                {STATUS_SPAN[values.status === "INCOMPLETED" ? "INCOMPLETED" : "COMPLETED"]}
              </div>
              <div className="flex flex-col justify-between border gap-2 p-4 rounded-lg bg-white">
                <p className="text-lg font-semibold">
                  {coupleValues.user_first_name} {coupleValues.user_last_name}
                </p>

                <p>
                  email : <span className="font-semibold">{coupleValues.user_email}</span>
                </p>
                <p>
                  numéro de téléphone : <span className="font-semibold">+33 {coupleValues.user_phone}</span>
                </p>
                <hr />
                <div className="flex space-x-4">
                  <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../couple", { state: { from: "SUMMARY" } })}>
                    <MdEdit />
                    <p>Modifier la fiche</p>
                  </button>
                  <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../couple/documents", { state: { from: "SUMMARY" } })}>
                    <HiOutlineDocumentPlus />
                    <p>Ajouter des documents</p>
                  </button>
                </div>
              </div>
              <h2 className="text-xl font-bold text-cyan-600">Son garant</h2>
              {coupleValues.has_guarantor && (
                <div className="flex flex-col justify-between border gap-2 p-4 rounded-lg bg-white">
                  <p className="text-lg font-semibold">
                    {coupleValues.guarantor_first_name} {coupleValues.guarantor_last_name}
                  </p>

                  <p>
                    email : <span className="font-semibold">{coupleValues.guarantor_email}</span>
                  </p>
                  <p>
                    numéro de téléphone : <span className="font-semibold">+33 {coupleValues.guarantor_phone}</span>
                  </p>
                  <hr />
                  <div className="flex space-x-4">
                    <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../couple/guarantor", { state: { from: "SUMMARY" } })}>
                      <MdEdit />
                      <p>Modifier la fiche</p>
                    </button>
                    <button className="text-gray-600 text-sm flex gap-1 items-center" onClick={() => navigate("../couple/guarantor/documents", { state: { from: "SUMMARY" } })}>
                      <HiOutlineDocumentPlus />
                      <p>Ajouter des documents</p>
                    </button>
                  </div>
                </div>
              )}
              {!coupleValues.has_guarantor && (
                <button
                  className="text-blue-600 border border-blue-600 py-2 px-4 rounded-lg"
                  onClick={() => {
                    const newValues = { ...coupleValues, has_guarantor: true };
                    setCoupleValues({ ...newValues });
                    onUpdate(newValues, true);
                    navigate("../couple/guarantor");
                  }}
                >
                  Ajouter un garant
                </button>
              )}
            </div>
            <div className="bg-green-50 p-6 rounded-lg h-fit">
              <h3 className="font-bold">Avec Doxallia :</h3>
              <ul className="list-disc list-inside">
                <li>Constituez votre dossier locataire une seule fois</li>
                <li>Postulez en ligne et réutilisez votre dossier dans toutes vos démarches</li>
              </ul>
            </div>

            <hr className="col-span-3" />
          </>
        )}

        <div className="col-span-2 flex flex-col gap-4 pr-12">
          <h2 className="text-xl font-bold mb-4">Finaliser mon dossier</h2>
          <div className=" bg-green-500 bg-opacity-10 border border-opacity-15 border-green-900 text-green-900 p-4 rounded-lg mb-4">
            <p>Avant de continuer, nous vous invitons à vous assurer que l'ensemble des éléments de votre dossier ont été renseignés.</p>
          </div>
          <div className="mb-4">
            <label className="flex items-start gap-3">
              <input type="checkbox" className="mt-1" onChange={(e) => setHasAcceptArticle(e.target.checked)} />
              <p>
                Je déclare avoir pris connaissance de l'article 441-1 du code pénal qui punit le faux et l'usage de faux de trois ans d'emprisonnement et de 45 000 euros d'amende.
              </p>
            </label>
          </div>
          <div className="mb-4">
            <label className="flex items-start gap-3">
              <input type="checkbox" className="mt-1" onChange={(e) => setHasAcceptData(e.target.checked)} />
              <p>
                J'autorise Doxallia à traiter l'ensemble des données et des pièces du dossier pour les finalités et dans les conditions précisées au sein de la{" "}
                <a href="#" className="text-blue-600">
                  Politique de protection des données du site
                </a>
              </p>
              .
            </label>
          </div>
          <button className="primary-button w-1/3" disabled={!hasAcceptArticle || !hasAcceptData} onClick={validateReport}>
            Finaliser mon dossier
          </button>
        </div>
      </div>
    </div>
  );
};

export default Summary;
