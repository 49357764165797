import React, { useEffect } from "react";

import useStore from "@/services/store";
import api from "@/services/api";
import Loader from "../../components/Loader";
import { useNavigate, useSearchParams } from "react-router-dom";

const TokenVerification = () => {
  const { setTenant } = useStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) navigate("/auth");
    const fetchTenant = async () => {
      try {
        const res = await api.post("/user/connect", { token });
        api.setToken(res.data.token);
        setTenant(res.data.user);
        navigate(`/user/${res.data.report._id}/tenant`);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTenant();
  }, []);

  return <Loader />;
};

export default TokenVerification;
