import { useState } from "react";
import toast from "react-hot-toast";
import { MdAddCircleOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import api from "@/services/api";
import Modal from "@/components/Modal";
import useStore from "@/services/store";

const NewPropertyModal = ({ onCreate }) => {
  const { user } = useStore();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    reference: "",
    address: "",
    company_id: user.company_id,
    company_name: "",
    price: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await api.post("/property", values);
      if (!res.ok) throw res;
      toast.success("Location créée");
      onCreate(res.data);
      setOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  return (
    <>
      <button onClick={() => setOpen(true)} className="primary-button text-sm">
        <MdAddCircleOutline className="mr-2 w-4 h-4" />
        Ajouter une nouvelle location
      </button>
      <Modal show={open} onClose={() => setOpen(false)}>
        <div>
          <div className="w-full flex justify-between">
            <h3 className="text-2xl font-bold">Nouvelle location</h3>
            <button onClick={() => setOpen(false)} className="transparent-button">
              <RxCross2 />
            </button>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8">
            <div className="space-y-2">
              <label htmlFor="address" className="text-sm font-semibold">
                Adresse du bien
              </label>
              <input type="text" id="name" className="input" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label htmlFor="reference" className="text-sm font-semibold">
                Numéro de référence
              </label>
              <input type="text" id="name" className="input" value={values.reference} onChange={(e) => setValues({ ...values, reference: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label htmlFor="manager" className="text-sm font-semibold">
                Responsable de la location
              </label>
              <input type="text" id="name" className="input" value={values.manager} onChange={(e) => setValues({ ...values, manager: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label htmlFor="price" className="text-sm font-semibold">
                Loyer mensuel
              </label>
              <input type="number" id="name" className="input" value={values.price} onChange={(e) => setValues({ ...values, price: e.target.value })} />
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <button className="primary-button" onClick={handleSubmit}>
              {loading ? "Chargement..." : "Créer la nouvelle location"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewPropertyModal;
