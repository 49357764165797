import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-hot-toast";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import Select from "../../../components/Select";
import Header from "../components/Header";
import { FaCheck } from "react-icons/fa6";

const SITUATION_LABEL = {
  SINGLE: "Seul.e",
  COUPLE: "Avec mon.ma conjoint.e",
  SHARED: "En collocation",
};

const SITUATION_TYPE = ["SINGLE", "COUPLE", "SHARED"];

const TenantForm = ({ values, setValues, setCoupleValues }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onUpdate = async (report) => {
    const data = report ? report : values;
    try {
      if (data?.situation === "COUPLE" && !data?.couple_report_id) {
        const res = await api.post("/report", {
          property_id: data.property_id,
          property_reference: data.property_reference,
          situation: data.situation,
          couple_report_id: data._id,
          company_id: data.company_id,
          company_name: data.company_name,
          company_email: data.company_email,
          company_doxallia_email: data.company_doxallia_email,
          is_spouse: true,
        });
        const response = await api.put(`/report/${data._id}`, { ...data, couple_report_id: res.data._id });
        if (!response.ok) throw response;
        setValues(response.data);
        setCoupleValues(res.data);
      } else {
        const res = await api.put(`/report/${data._id}`, data);
        if (!res.ok) throw res;
        setValues(res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRedirection = (type) => {
    if (type === "SUMMARY") navigate("../summary");
    else {
      if (values.situation === "COUPLE") navigate("../couple");
      else if (values.has_guarantor) navigate("./guarantor");
      else navigate("./documents");
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center px-32 py-6 gap-8">
      <div className="rounded bg-white border p-6">
        <Header status={values?.status} home={true} />
      </div>
      <Details values={values} setValues={setValues} onUpdate={onUpdate} />
      <div className="w-full flex justify-center">
        <button onClick={() => handleRedirection(location.state?.from === "SUMMARY" ? "SUMMARY" : "NEXT")} className="primary-button flex items-center gap-2">
          <p>{location.state?.from === "SUMMARY" ? "Valider et retourner le sommaire" : "Suivant"}</p>
          <IoArrowForwardCircleOutline className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

const Details = ({ values, setValues, onUpdate }) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold w-full px-4 py-2 bg-primary text-white rounded">
        Détails du locataire : {values.user_first_name} {values.user_last_name}
      </h1>
      <div className="grid grid-cols-2 gap-x-2 gap-y-5 rounded bg-white border p-4">
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="first_name">
            Prénom
          </label>
          <input
            type="text"
            name="first_name"
            className="input"
            value={values?.user_first_name}
            onChange={(e) => setValues({ ...values, user_first_name: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez votre prénom"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="last_name">
            Nom
          </label>
          <input
            type="text"
            name="last_name"
            className="input"
            value={values?.user_last_name}
            onChange={(e) => setValues({ ...values, user_last_name: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez votre nom"
          />
        </div>
        <div className="space-y-2 col-span-2">
          <label className="text-base font-medium" htmlFor="email">
            Adresse e-mail
          </label>
          <input
            type="email"
            name="email"
            className="input"
            value={values?.user_email}
            onChange={(e) => setValues({ ...values, user_email: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez votre adresse e-mail"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Numéro de téléphone
          </label>
          <div className="flex items-center gap-3">
            +33
            <input
              type="input"
              className="input"
              max={10}
              value={values?.user_phone}
              onChange={(e) => setValues({ ...values, user_phone: e.target.value.slice(0, 9) })}
              onBlur={() => onUpdate()}
              placeholder="6XXXXXXXX"
            />
          </div>
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Référence de la location
          </label>
          <input
            type="text"
            name="property_reference"
            className="input"
            value={values?.property_reference}
            onChange={(e) => setValues({ ...values, property_reference: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez la référence de la location"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Je souhaite emménager
          </label>
          <Select
            position={"bottom-full left-0"}
            options={SITUATION_TYPE}
            value={values?.situation}
            onChange={(e) => onUpdate({ ...values, situation: e })}
            getLabel={(type) => SITUATION_LABEL[type]}
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Avez-vous un garant ?
          </label>
          <Select
            position={"bottom-full left-0"}
            options={["Non", "Oui"]}
            value={values?.has_guarantor ? "Oui" : "Non"}
            onChange={(e) => onUpdate({ ...values, has_guarantor: e === "Oui" })}
          />
        </div>
      </div>
    </div>
  );
};

export default TenantForm;
