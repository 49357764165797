import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate, Navigate, useSearchParams, Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import TenantForm from "./pages/form";
import Properties from "./pages/property";
import Candidates from "./pages/candidate";
import api from "./services/api";
import useStore from "./services/store";
import Loader from "./components/Loader";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";

import backgroundImage from "./assets/backgroundSignup.png";
import AuthLogo from "./assets/Logo-White.png";
import Navbar from "./components/NavBar";
import TopBar from "./components/TopBar";
import TokenVerification from "./pages/auth/TokenVerification";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/auth" element={<Signin />} />
            <Route path="/auth/signup" element={<Signup />} />
            <Route path="/" element={<TokenVerification />} />
          </Route>
          <Route element={<UserLayout />}>
            <Route path="/user/:id/*" element={<TenantForm />} />
          </Route>
          <Route element={<AgencyLayout />}>
            <Route path="/dashboard/properties/*" element={<Properties />} />
            <Route path="/dashboard/candidates/*" element={<Candidates />} />
          </Route>
          <Route path="/error-not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/dashboard/properties" />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" />
    </>
  );
};

const NotFound = () => {
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className="bg-cover bg-center">
      <div className="flex flex-col items-center justify-center min-h-screen text-white">
        <h1 className="text-9xl font-bold">404</h1>
        <h2 className="text-4xl mt-4">Oups! La page que vous cherchez n'a pas pu être trouvée.</h2>
        <p className="text-lg mt-2">Il semble que cette page n'existe pas. Veuillez vérifier l'URL.</p>
      </div>
    </div>
  );
};

const AuthLayout = () => {
  const { user } = useStore();
  if (user) return <Navigate to="/dashboard/properties" replace={true} />;
  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }} className="bg-cover bg-center">
      <div className="flex flex-col justify-center items-center gap-8 w-screen h-screen">
        <img src={AuthLogo} alt="logo" className=" w-44" />
        <Outlet />
      </div>
    </div>
  );
};

const UserLayout = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const { token, setTenant } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get("/user/refresh");
        if (res.ok) {
          setTenant(res.data.user);
          api.setToken(res.data.token);
        } else {
          setTenant(null);
          navigate("/error-not-found");
        }
      } catch (e) {
        setTenant(null);
        navigate("/error-not-found");
      }
      setLoading(false);
    };
    fetchData();
  }, [navigate, token, setTenant]);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="relative w-screen min-h-screen bg-gray-50">
      <Outlet />
    </div>
  );
};

const AgencyLayout = () => {
  const { user, setUser } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api
      .get("/user/refresh")
      .then((res) => {
        if (res.ok) {
          setUser(res.data.user);
          api.setToken(res.data.token);
        } else {
          setUser(null);
          navigate("/auth");
        }
        setLoading(false);
      })
      .catch(() => setUser(null));
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  if (!user || user.role === "user") {
    setUser(null);
    return <Navigate to="/auth" replace={true} />;
  }

  return (
    <div className="relative w-screen h-screen">
      <nav className="w-56 absolute left-0 top-0">
        <Navbar />
      </nav>
      <main className="ml-56 h-full overflow-auto bg-gray-50">
        <div className="h-14 border-b border-secondary bg-white">
          <TopBar />
        </div>
        <Outlet />
      </main>
    </div>
  );
};

export default App;
