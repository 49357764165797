import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import TenantForm from "./tenant/TenantForm";
import TenantDocuments from "./tenant/TenantDocuments";
import toast from "react-hot-toast";
import api from "../../services/api";
import Summary from "./Summary";
import GuarantorForm from "./guarantor/GuarantorForm";
import GuarantorDocuments from "./guarantor/GuarantorDocuments";
import GuarantorDocumentsSelection from "./guarantor/GuarantorDocumentsSelection";
import Loader from "../../components/Loader";
import CoupleForm from "./couple/CoupleForm";
import CoupleDocumentsSelection from "./couple/CoupleDocumentsSelection";
import CoupleDocuments from "./couple/CoupleDocuments";
import CoupleGuarantorForm from "./coupleGuarantor/CoupleGuarantorForm";
import CoupleGuarantorDocuments from "./coupleGuarantor/CoupleGuarantorDocuments";
import CoupleGuarantorDocumentsSelection from "./coupleGuarantor/CoupleGuarantorDocumentsSelection";

const Form = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    user_email: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    property_reference: "",
    situation: "SINGLE",
    has_guarantor: false,
    guarantor_first_name: "",
    guarantor_last_name: "",
    guarantor_email: "",
    guarantor_phone: "",
    document_ids: [],
  });
  const [coupleValues, setCoupleValues] = useState({
    user_email: "",
    user_first_name: "",
    user_last_name: "",
    user_phone: "",
    property_reference: "",
    situation: "SINGLE",
    has_guarantor: false,
    guarantor_first_name: "",
    guarantor_last_name: "",
    guarantor_email: "",
    guarantor_phone: "",
    document_ids: [],
  });

  useEffect(() => {
    fetchReport();
  }, []);

  const fetchReport = async () => {
    setLoading(true);
    try {
      const res = await api.get(`/report/${id}`);
      if (!res.ok) throw res;
      const data = res.data;
      if (data.situation === "COUPLE" && data.couple_report_id) {
        const res = await api.get(`/report/${data.couple_report_id}`);
        if (!res.ok) throw res;
        setCoupleValues(res.data);
      }
      setValues(data);
    } catch (error) {
      console.error(error.message);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  return (
    <Routes>
      <Route path="/summary" element={<Summary values={values} setValues={setValues} coupleValues={coupleValues} setCoupleValues={setCoupleValues} />} />
      <Route path="/tenant" element={<TenantForm values={values} setValues={setValues} setCoupleValues={setCoupleValues} coupleValues={coupleValues} />} />
      <Route path="/tenant/documents" element={<TenantDocuments values={values} setValues={setValues} coupleValues={coupleValues} />} />
      <Route path="/tenant/guarantor" element={<GuarantorForm values={values} setValues={setValues} />} />
      <Route path="/tenant/guarantor/documents" element={<GuarantorDocuments values={values} setValues={setValues} coupleValues={coupleValues} />} />
      <Route
        path="/tenant/guarantor/documents-selection"
        element={<GuarantorDocumentsSelection values={values} setValues={setValues} document_ids={[...values.document_ids, ...coupleValues.document_ids]} />}
      />
      <Route path="/couple" element={<CoupleForm values={coupleValues} setValues={setCoupleValues} />} />
      <Route path="/couple/documents-selection" element={<CoupleDocumentsSelection values={values} coupleValues={coupleValues} setCoupleValues={setCoupleValues} />} />
      <Route path="/couple/documents" element={<CoupleDocuments values={coupleValues} setValues={setCoupleValues} coupleValues={values} />} />
      <Route path="/couple/guarantor" element={<CoupleGuarantorForm values={coupleValues} setValues={setCoupleValues} />} />
      <Route path="/couple/guarantor/documents" element={<CoupleGuarantorDocuments values={values} coupleValues={coupleValues} setValues={setCoupleValues} />} />
      <Route
        path="/couple/guarantor/documents-selection"
        element={<CoupleGuarantorDocumentsSelection values={coupleValues} setValues={setCoupleValues} document_ids={[...values.document_ids, ...coupleValues.document_ids]} />}
      />
    </Routes>
  );
};

export default Form;
