import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdEdit, MdDeleteForever } from "react-icons/md";

import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";
import api from "@/services/api";
import useStore from "../../services/store";
import NewPropertyModal from "./components/NewPropertyModal";
import TablePagination from "../../components/TablePagination";

const HEADER = [
  { key: "reference", title: "Numéro reférence" },
  { key: "address", title: "Adresse du bien" },
  { key: "price", title: "Loyer mensuel" },
  { key: "manager", title: "Responsable" },
  { key: "status", title: "État du bien" },
  { key: "actions", title: "", with: "w-20" },
];

const List = () => {
  const { user } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    page: searchParams.get("page") || 1,
    limit: 50,
    companies: user.company_id,
  });

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const query = {
        page: filters.page,
        limit: filters.limit,
        companies: filters.companies,
      };

      const res = await api.post(`/property/search`, query);
      if (!res.ok) throw res;
      setData(res.data);
      setTotal(res.total);
      setSearchParams({ page: filters.page });
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const handleDelete = async (property) => {
    const res = window.confirm("Voulez-vous vraiment supprimer cette location ?");
    if (!res) return;

    try {
      const res = await api.delete(`/property/${property._id}`);
      if (!res.ok) throw res;
      fetchData();
    } catch (error) {
      toast.error("une erreur est survenu");
    }
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8 space-y-8">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl text-primary font-bold">{`Liste des locations (${total})`}</h1>
        <NewPropertyModal onCreate={(property) => setData([property, ...data])} />
      </div>

      <div className="w-full">
        <TablePagination page={filters.page} pageSize={filters.pageSize} onPageChange={(page) => setFilters({ ...filters, page })} header={HEADER} total={total} loading={loading}>
          {data.map((item, index) => (
            <tr
              key={index}
              className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
              onClick={() => {
                navigate(`/dashboard/candidates/?property=${item._id}`);
              }}
            >
              <td className="py-4 px-3 text-left">{item.reference}</td>
              <td className="py-4 px-3 overflow-hidden whitespace-nowrap text-ellipsis text-left max-w-60">{item.address}</td>
              <td className="py-4 px-3 text-left">{item.price}</td>
              <td className="py-4 px-3 text-left">{item.manager}</td>
              <td className="py-4 px-3 text-left">{STATUS_SPAN[item.status]}</td>
              <td className="py-4 flex justify-center gap-1">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/properties/${item._id}`);
                  }}
                  className="p-1.5 rounded-full hover:bg-indigo-200"
                >
                  <MdEdit />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(item);
                  }}
                  className="p-1.5 rounded-full hover:bg-red-100"
                >
                  <MdDeleteForever color="red" />
                </button>
              </td>
            </tr>
          ))}
        </TablePagination>
      </div>
    </div>
  );
};

const STATUS_SPAN = {
  AVAILABLE: <span className="rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">Disponible</span>,
  PENDING: <span className="rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">En attente</span>,
  RENTED: <span className="rounded-md bg-violet-100 px-3 py-1 text-xs font-medium text-violet-600 border border-violet-600">Loué</span>,
};

export default List;
