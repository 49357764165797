const STATUS_SPAN = {
  INCOMPLETED: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <span className={`h-2 w-2 rounded-full bg-orange-400`} /> Incomplet
    </span>
  ),
  COMPLETED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <span className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  PROCESSING: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-purple-100 px-3 py-1 text-xs font-medium text-purple-600 border border-purple-600">
      <span className={`h-2 w-2 rounded-full bg-purple-400`} />
      En traitement
    </span>
  ),
  VERIFIED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <span className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  FINALIZED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <span className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  // VERIFIED: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">
  //     <span className={`h-2 w-2 rounded-full bg-green-400`} />
  //     Traité
  //   </span>
  // ),
  // FINALIZED: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
  //     <span className={`h-2 w-2 rounded-full bg-blue-400`} />
  //     Finalisé
  //   </span>
  // ),
  // SENT_TO_AGENCY: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
  //     <span className={`h-2 w-2 rounded-full bg-blue-400`} />
  //     Finalisé
  //   </span>
  // ),
};

export { STATUS_SPAN };
