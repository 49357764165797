import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loader from "@/components/Loader";
import Pagination from "@/components/Pagination";

import api from "@/services/api";
import useStore from "../../services/store";
import MultiSelect from "../../components/MultiSelect";
import Table from "../../components/Table";

import { FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import TablePagination from "../../components/TablePagination";

const STATUS_OPTIONS = [
  { value: "INCOMPLETED", label: "Incomplet" },
  { value: "PROCESSING", label: "En traitement" },
  { value: "COMPLETED", label: "Complet" },
];

const SITUATION_OPTIONS = [
  { value: "COUPLE", label: "Couple" },
  { value: "SINGLE", label: "Seul.e" },
];

const HEADER = [
  { title: "Situation", key: "situation", width: "w-20", position: "between" },
  { title: "Garant", key: "has_guarantor", width: "w-20", position: "between" },
  { title: "Nom complet", key: "user_first_name", position: "between" },
  { title: "Date de dépot", key: "created_at", position: "between" },
  { title: "Revenu fiscal de référence", key: "payslip_gross_salary", position: "between" },
  { title: "Ref. propriété", key: "property_reference", position: "between" },
  { title: "Statut", property: "w-40" },
  { title: "Score", key: "final_score", position: "between", width: "w-40" },
];

const List = () => {
  const { user } = useStore();
  const [searchParams] = useSearchParams();
  const property = searchParams.get("property");
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    companies: user.company_id ? [user.company_id] : [],
    statut: null,
    situation: null,
    properties: property ? [property] : null,
    sort: { created_at: -1 },
  });

  const handleSort = (key) => {
    setFilters({ ...filters, sort: { [key]: !filters.sort[key] ? 1 : filters.sort[key] === 1 ? -1 : undefined } });
  };

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState(false);

  const fetchData = async () => {
    if (data.length === 0) setLoading(true);
    else setLoadingRow(true);
    try {
      const res = await api.post("/report/search", {
        skip: (filters.page - 1) * filters.pageSize,
        limit: filters.pageSize,
        companies: filters.companies,
        status: filters.statut,
        situation: filters.situation,
        properties: filters.properties,
        sort: filters.sort,
      });
      if (res.ok) {
        setData(res.data);
        setTotal(res.total);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
    setLoadingRow(false);
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    getProperties();
  }, []);

  const getProperties = async () => {
    const { data, ok } = await api.post(`/property/search`, {
      companies: filters.companies,
    });
    if (!ok) return toast.error("Error!");
    setProperties(data);
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8 space-y-8">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl text-primary font-bold">Liste des candidatures</h1>
        <div className="flex gap-2 items-center">
          <MultiSelect
            values={filters.situation?.map((statut) => ({ value: statut }))}
            onSelectedChange={(e) => {
              const situation = e.map((e) => e.value);
              setFilters({ ...filters, situation: situation });
            }}
            placeholder="Situation"
            options={SITUATION_OPTIONS}
            renderOption={(item) => <div>{item}</div>}
          />
          <MultiSelect
            values={filters.statut?.map((statut) => ({ value: statut }))}
            onSelectedChange={(e) => {
              const statut = e.map((e) => e.value);
              setFilters({ ...filters, statut: statut });
            }}
            placeholder="Statut"
            options={STATUS_OPTIONS}
            renderOption={(item) => <div>{item}</div>}
          />
          <MultiSelect
            values={filters.properties?.map((propertyId) => ({ value: propertyId }))}
            onSelectedChange={(e) => {
              const ids = e.map((e) => e.value);
              setFilters({ ...filters, properties: ids });
            }}
            placeholder="Ref. propriétés"
            options={properties.map((f) => ({ value: f._id, label: f.reference }))}
            renderOption={(item) => <div>{item}</div>}
          />
        </div>
      </div>
      <div className="w-full">
        <TablePagination
          page={filters.page}
          pageSize={filters.pageSize}
          onPageChange={(page) => setFilters({ ...filters, page })}
          header={HEADER}
          sort={filters.sort}
          total={total}
          onSort={handleSort}
          loading={loading}
        >
          {data
            .filter((e) => !e.is_spouse)
            .map((item, index) => (
              <tr
                key={index}
                className={`text-center cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`}
                onClick={() => {
                  if (item.situation === "COUPLE") navigate(`/dashboard/candidates/${item._id}/overview`);
                  else navigate(`/dashboard/candidates/${item._id}/tenant`);
                }}
              >
                <td className="py-4 px-3 text-right">{SITUATION_SPAN[item.situation]}</td>
                <td className="py-4 px-3 text-left">{item.has_guarantor && SITUATION_SPAN["GUARANTOR"]}</td>
                <td className="py-4 px-3 text-left">
                  {item.user_first_name} {item.user_last_name}
                </td>
                <DateTableCell d={item.created_at} />
                <td className="py-4 px-3 text-left">
                  {item.situation === "COUPLE"
                    ? item.overview_global_income
                      ? `${item.overview_global_income?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}`
                      : "-"
                    : item.payslip_gross_salary
                    ? `${(item.payslip_gross_salary * 0.77)?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}`
                    : "-"}
                </td>
                <td className="py-4 px-3 text-left">{item.property_reference ?? "-"}</td>
                <td className="py-4 px-3 text-left">{STATUS_SPAN[item.status]}</td>
                <td className="py-4 px-3 text-left">
                  {item.situation === "COUPLE" ? (
                    item.overview_global_score !== undefined ? (
                      <ScoreIndicator score={item.overview_global_score} />
                    ) : (
                      "-"
                    )
                  ) : item.final_score !== undefined ? (
                    <ScoreIndicator score={item.final_score} />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
        </TablePagination>
      </div>
    </div>
  );
};

const STATUS_SPAN = {
  INCOMPLETED: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <div className={`h-2 w-2 rounded-full bg-orange-400`} /> Incomplet
    </span>
  ),
  COMPLETED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <div className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  PROCESSING: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-purple-100 px-3 py-1 text-xs font-medium text-purple-600 border border-purple-600">
      <div className={`h-2 w-2 rounded-full bg-purple-400`} />
      En traitement
    </span>
  ),

  VERIFIED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <div className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  FINALIZED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <div className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  // VERIFIED: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">
  //     <div className={`h-2 w-2 rounded-full bg-green-400`} />
  //     Traité
  //   </span>
  // ),
  // FINALIZED: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
  //     <div className={`h-2 w-2 rounded-full bg-blue-400`} />
  //     Finalisé
  //   </span>
  // ),
  // SENT_TO_AGENCY: (
  //   <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
  //     <div className={`h-2 w-2 rounded-full bg-blue-400`} />
  //     Finalisé
  //   </span>
  // ),
};

const ScoreIndicator = ({ score }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="bg-gray-400 rounded-full my-2 overflow-hidden h-2 w-2/3">
        {score > 0 && <div className={`bg-primary h-full text-white flex justify-end items-center pr-2 text-sm font-bold`} style={{ width: `${score}%` }} />}
      </div>
      <p className="text-sm font-semibold text-primary">{score}%</p>
    </div>
  );
};

const DateTableCell = ({ d }) => {
  const date = new Date(d);
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("fr-FR", options);

  return <td className="py-4 px-3 text-left">{formattedDate}</td>;
};

const SITUATION_SPAN = {
  COUPLE: <span className="inline-flex items-center gap-1.5  rounded-md bg-white px-3 py-1 text-xs font-medium text-pink-300 border border-pink-300">Couple</span>,
  SINGLE: <span className="inline-flex items-center gap-1.5 rounded-md bg-white px-3 py-1 text-xs font-medium text-primary border border-primary">Seul.e</span>,
  GUARANTOR: <span className="inline-flex items-center gap-1.5 rounded-md bg-white px-3 py-1 text-xs font-medium text-lime-300 border border-lime-300">Garant</span>,
};

export default List;
