const STATUS_SPAN = {
  INCOMPLETED: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <div className={`h-2 w-2 rounded-full bg-orange-400`} /> Incomplet
    </span>
  ),
  COMPLETED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <div className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
};

export default STATUS_SPAN;
