import { MdOutlineInsertDriveFile, MdRecommend } from "react-icons/md";
import { FaCheckCircle, FaCloudUploadAlt, FaFileUpload, FaRegTrashAlt, FaTimesCircle } from "react-icons/fa";
import { FaFileLines } from "react-icons/fa6";
import Select from "../../../components/Select";
import { useEffect, useState } from "react";
import api from "../../../services/api";

const DOCUMENT_TYPE_LABEL = {
  ID: "Document d'identité",
  RENT_RECEIPT: "Justificatif d'hébergement",
  TAX_NOTICE: "Avis d'imposition",
  INCOME: "Bulletins de salaire",
  OTHER: "Autre",
};

const DOCUMENT_TYPES = ["OTHER", "ID", "RENT_RECEIPT", "TAX_NOTICE", "INCOME"];

const Document = ({ values, onChange, title, type, person, report, required = "MANDATORY" }) => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const handleUploadFile = async (e) => {
    e.persist();
    setLoading(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      formData.append("type", type);
      formData.append("person", person);
      if (person === "GUARANTOR") {
        formData.append("guarantor_first_name", report.guarantor_first_name);
        formData.append("guarantor_last_name", report.guarantor_last_name);
        formData.append("guarantor_email", report.guarantor_email);
      } else {
        if (report.user_id) formData.append("user_id", report.user_id);
        formData.append("user_first_name", report.user_first_name);
        formData.append("user_last_name", report.user_last_name);
        formData.append("user_email", report.user_email);
      }
      const { data } = await api.postFormData(`/document`, formData);
      onChange([...values, ...data]);
      e.target.value = null;
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setDocuments(values?.filter((d) => d.type === type && d.persona === person));
  }, [values]);

  const onUpdate = async (document) => {
    try {
      const res = await api.put(`/document/${document._id}`, document);
      if (!res.ok) throw res;
      const updatedDocuments = values.map((doc) => (doc._id === document._id ? document : doc));
      onChange(updatedDocuments);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteFile = async (doc) => {
    try {
      await api.delete(`/document/${doc._id}`);
      const updatedDocuments = values.filter((d) => d._id !== doc._id);
      onChange(updatedDocuments);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full rounded bg-white border p-6 grid grid-cols-4 gap-y-4">
      <div className="flex items-center col-span-3 gap-2">
        <Icon required={required} documents={documents} type={type} />
        <p className="text-black text-lg font-semibold">{title}</p>
        <Badge required={required} />
      </div>
      <h3 className="text-lg font-semibold">Type de document</h3>
      {documents?.map((doc, i) => (
        <div key={i} className="grid grid-cols-4 col-span-4">
          <div className="flex items-center justify-between gap-4 col-span-3 bg-gray-100 py-2 rounded border mr-4">
            <div className="flex items-center gap-2 pl-4">
              <FaFileLines className="w-5 h-5 text-primary mb-1" />
              <a href={doc.url} target="_blank" className="text-base text-primary hover:underline">
                {doc.name}
              </a>
            </div>
            <button onClick={() => handleDeleteFile(doc)} className="border-l px-2">
              <FaRegTrashAlt className="w-5 h-5 text-black" />
            </button>
          </div>
          <div>
            <Select options={DOCUMENT_TYPES} value={doc?.type} onChange={(value) => onUpdate({ ...doc, type: value })} getLabel={(type) => DOCUMENT_TYPE_LABEL[type]} />
          </div>
          {/* <div className="col-span-4">
            <button className="text-base text-primary underline text-left pt-1">Changer</button>
          </div> */}
        </div>
      ))}
      {(type === "OTHER" || (type === "INCOME" && documents.length < 3) || (type !== "OTHER" && type !== "INCOME" && documents.length < 1)) && (
        <label
          htmlFor={type}
          className="py-2 mr-4 bg-white hover:bg-gray-50 transition-all cursor-pointer flex items-center justify-center gap-6 border rounded border-gray-200 border-dashed col-span-3"
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="w-4 h-4 rounded-full bg-primary animate-bounce" />
              <p className="text-primary">Chargement...</p>
            </div>
          ) : (
            <>
              <FaCloudUploadAlt className="w-6 h-6 text-primary" />
              <p>Cliquez pour déposez un fichier</p>
            </>
          )}
        </label>
      )}
      <input id={type} type="file" multiple={true} className="hidden" onChange={handleUploadFile} />
      <div className="flex flex-col">
        <div className="flex items-center justify-between"></div>
      </div>
    </div>
  );
};

const Icon = ({ required, documents, type }) => {
  if (required === "MANDATORY") {
    if (type !== "INCOME" && documents && documents.length > 0) {
      return <FaCheckCircle className="w-6 h-6 text-green-500" />;
    } else if (type === "INCOME" && documents && documents.length > 2) {
      return <FaCheckCircle className="w-6 h-6 text-green-500" />;
    } else {
      return <FaTimesCircle className="w-6 h-6 text-red-500" />;
    }
  } else if (required === "OPTIONAL") {
    return <MdRecommend className="w-6 h-6 text-orange-500" />;
  }
  return null;
};

const Badge = ({ required }) => {
  if (required === "MANDATORY") {
    return (
      <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-2 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
        <div className="h-2 w-2 rounded-full bg-cyan-400" /> Obligatoire
      </span>
    );
  } else if (required === "OPTIONAL") {
    return (
      <span className="inline-flex items-center gap-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 border border-orange-600">
        <div className="h-2 w-2 rounded-full bg-orange-400" /> Recommandé
      </span>
    );
  } else {
    return (
      <span className="inline-flex items-center gap-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-600 border border-purple-600">
        <div className="h-2 w-2 rounded-full bg-purple-400" /> Facultatif
      </span>
    );
  }
};

export default Document;
