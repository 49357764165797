import { create } from "zustand";

const useStore = create((set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  tenant: null,
  setTenant: (tenant) => set(() => ({ tenant })),
}));

export default useStore;
