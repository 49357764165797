import api from "../../../services/api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from "react-icons/io5";

const DOCUMENT_TITLE = {
  ID: "Document d'identité",
  TAX_NOTICE: "Avis d'imposition",
  INCOME: "3 derniers bulletins de salaire",
  RENT_RECEIPT: "Justificatif d'hébergement",
  OTHER: "Autre",
};

const CoupleGuarantorDocumentsSelection = ({ values }) => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (values.document_ids) fetchData();
  }, [values.document_ids]);

  useEffect(() => {
    let TempSelectedDocuments = [];
    documents.forEach((doc) => {
      if (doc.persona === "GUARANTOR") {
        TempSelectedDocuments.push(doc._id);
      }
    });
    setSelectedDocuments(TempSelectedDocuments);
  }, [documents]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post("/document/search", { documents: values.document_ids });
      if (res.ok) {
        setDocuments(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleRedirection = async (type) => {
    if (type === "NEXT") {
      documents.forEach(async (doc) => {
        try {
          if (selectedDocuments.includes(doc._id)) {
            const res = await api.put(`/document/${doc._id}`, {
              ...doc,
              persona: "GUARANTOR",
              guarantor_email: values.guarantor_email,
              guarantor_first_name: values.guarantor_first_name,
              guarantor_last_name: values.guarantor_last_name,
              user_id: null,
              user_email: null,
              user_first_name: null,
              user_last_name: null,
            });
            if (!res.ok) throw res;
          } else {
            const res = await api.put(`/document/${doc._id}`, {
              ...doc,
              persona: "TENANT",
              guarantor_email: null,
              guarantor_first_name: null,
              guarantor_last_name: null,
              user_id: values.user_id || null,
              user_email: values.user_email,
              user_first_name: values.user_first_name,
              user_last_name: values.user_last_name,
            });
            if (!res.ok) throw res;
          }
        } catch (error) {
          console.error(error.message);
        }
      });
      navigate("../couple/guarantor/documents");
    } else {
      navigate("../couple/guarantor");
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center px-32 py-6 gap-4">
      <div className="rounded bg-white border p-6">
        <Header status={values?.status} />
      </div>
      <h1 className="text-lg font-semibold w-full px-4 py-2 mt-4 bg-pink-600 text-white rounded">
        Sélection des documents du garant de votre conjoint.e : {values.guarantor_first_name} {values.guarantor_last_name}
      </h1>

      <div className="rounded bg-white border px-12 py-8 w-full flex flex-col gap-4">
        <h1 className="text-2xl font-semibold w-full">
          Sélectionnez les <span className="text-sky-700">pièces justificatives</span> du garant de votre <span className="text-sky-700">conjoint.e</span>
        </h1>
        {documents.map((doc, index) => (
          <div
            key={index}
            className={`border px-6 py-2 border-opacity-40 rounded flex items-start gap-2 hover:border-primary cursor-pointer ${
              selectedDocuments.includes(doc._id) ? "border-primary border-opacity-100" : ""
            }`}
            onClick={() => {
              if (selectedDocuments.includes(doc._id)) {
                setSelectedDocuments(selectedDocuments.filter((id) => id !== doc._id));
              } else {
                setSelectedDocuments([...selectedDocuments, doc._id]);
              }
            }}
          >
            <input readOnly type="checkbox" id={doc._id} name={doc._id} checked={selectedDocuments.includes(doc._id)} className="mt-1.5 w-4 h-4 rounded" />
            <div className="flex flex-col justify-between gap-2">
              <label htmlFor={doc._id}>{doc.name}</label>
              <span className="w-fit inline-flex items-center gap-1.5  rounded-md bg-primary bg-opacity-15 px-2 py-1 text-xs font-medium text-primary border border-primary">
                <div className={`h-2 w-2 rounded-full bg-primary`} /> {DOCUMENT_TITLE[doc.type]}
              </span>
            </div>
          </div>
        ))}
        {documents.length === 0 && (
          <div className={`border px-6 py-2 border-opacity-40 rounded flex items-start gap-2 border-primary`}>
            <div className="flex flex-col justify-between font-medium p-4">Aucun document trouvé dirigez vous à la prochaine étape pour en ajouter des nouveaux</div>
          </div>
        )}
      </div>
      <div className="w-full flex justify-between">
        <button onClick={() => handleRedirection("BACK")} className="secondary-button flex items-center gap-2">
          <IoArrowBackCircleOutline className="w-6 h-6" />
          <p>Retour</p>
        </button>
        <button onClick={() => handleRedirection("NEXT")} className="primary-button flex items-center gap-2">
          <p>Suivant</p>
          <IoArrowForwardCircleOutline className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default CoupleGuarantorDocumentsSelection;
