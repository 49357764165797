import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Routes, Route } from "react-router-dom";
import toast from "react-hot-toast";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import api from "@/services/api";
import Loader from "@/components/Loader";
import ReportDetails from "./ReportDetails";
import { STATUS_SPAN } from "../constants";

import { FaTrash, FaCloudDownloadAlt } from "react-icons/fa";
import Overview from "./Overview";

const Index = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingZip, setLoadingZip] = useState(false);

  const [report, setReport] = useState({});
  const [coupleReport, setCoupleReport] = useState({});
  const [score, setScore] = useState({});
  const [coupleScore, setCoupleScore] = useState({});
  const [tab, setTab] = useState("tenant");
  const [tenant, setTenant] = useState({});
  const [coupleTenant, setCoupleTenant] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const resReport = await api.get(`/report/${id}`);
        if (resReport.ok) {
          setReport(resReport.data);
        }

        if (resReport.data.has_guarantor) {
          const res = await api.get(`/scoring/${resReport.data.guarantor_scoring_id}`);
          const tenantRes = await api.get(`/scoring/${resReport.data.scoring_id}`);
          if (res.ok && tenantRes.ok) {
            setScore(res.data);
            setTenant(tenantRes.data);
          }
        } else {
          const res = await api.get(`/scoring/${resReport.data.scoring_id}`);
          setScore(res.data);
        }

        if (resReport.data.situation === "COUPLE") {
          setTab("overview");
          const resCoupleReport = await api.get(`/report/${resReport.data.couple_report_id}`);
          if (resCoupleReport.ok) {
            setCoupleReport(resCoupleReport.data);
          }

          if (resCoupleReport.data.has_guarantor) {
            const res = await api.get(`/scoring/${resCoupleReport.data.guarantor_scoring_id}`);
            const tenantRes = await api.get(`/scoring/${resCoupleReport.data.scoring_id}`);
            if (res.ok && tenantRes.ok) {
              setCoupleScore(res.data);
              setCoupleTenant(tenantRes.data);
            }
          } else {
            const res = await api.get(`/scoring/${resCoupleReport.data.scoring_id}`);
            setCoupleScore(res.data);
          }
        }
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleDownload = async () => {
    setLoadingPDF(true);
    try {
      try {
        const response = await api.pdf(`/report/${report._id}/pdf`);
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `rapport-dossier-${report.user_first_name}-${report.user_last_name}-${report.property_reference}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
      }
    } catch (e) {
      console.error(e);
    }
    setLoadingPDF(false);
  };

  const handleDownloadZip = async () => {
    setLoadingZip(true);
    try {
      const res = await api.post("/document/download-zip", { document_ids: report.document_ids });
      const zip = new JSZip();
      res.data.forEach((doc) => {
        zip.file(doc.name, doc.data, { base64: true });
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `pieces-justificatives-${report.user_first_name}-${report.user_last_name}.zip`);
      });
    } catch (error) {
      console.error(error);
    }
    setLoadingZip(false);
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/report/${report._id}`);
      if (!res.ok) throw res;
      toast.success("Le dossier a été supprimé");
      navigate("/dashboard/candidates");
    } catch (error) {
      toast.error("une erreur est survenue");
    }
  };

  const handleTab = (tab) => {
    setTab(tab);
    navigate(`./${tab}`);
  };

  console.log(score);

  if (loading) return <Loader />;
  return (
    <div className="p-8">
      <div className="w-full flex justify-between items-center mb-8">
        <div className="flex items-center">
          {STATUS_SPAN[report.status]}
          <h1 className="text-2xl font-bold ml-5 text-primary">
            {report.user_first_name} {report.user_last_name}
          </h1>
        </div>
        <div className="flex items-center gap-4">
          <button onClick={handleDownload} className="primary-button rounded-md" disabled={report.status === "PROCESSING" || report.status === "INCOMPLETED"}>
            {loadingPDF ? "Chargement..." : "Télecharger le rapport"} <FaCloudDownloadAlt className="ml-2" />
          </button>
          <button onClick={handleDownloadZip} className="transparent-button-border rounded-md">
            {loadingZip ? "Chargement..." : "Télecharger les pièces justi."} <FaCloudDownloadAlt className="ml-2" />
          </button>
          <button onClick={handleDelete} className="red-out-button rounded-md">
            Supprimer
            <FaTrash className="ml-2" />
          </button>
        </div>
      </div>
      {report.situation === "COUPLE" && (
        <div className="flex items-center relative">
          <button
            className={`flex items-center justify-center px-4 py-2 cursor-pointer relative transition-all duration-300 border-b ${
              tab === "overview" ? "text-primary border-primary" : "text-gray-500 border-white"
            }`}
            onClick={() => handleTab("overview")}
          >
            Vue d'ensemble
          </button>
          <button
            className={`flex items-center justify-center px-4 py-2 cursor-pointer relative transition-all duration-300 border-b ${
              tab === "tenant" ? "text-primary border-primary" : "text-gray-500 border-white"
            }`}
            onClick={() => handleTab("tenant")}
          >
            Locataire 1
          </button>
          <button
            className={`flex items-center justify-center px-4 py-2 cursor-pointer relative transition-all duration-300 border-b ${
              tab === "couple" ? "text-primary border-primary" : "text-gray-500 border-white"
            }`}
            onClick={() => handleTab("couple")}
          >
            Locataire 2
          </button>
        </div>
      )}
      <section className="mt-8">
        {report.situation === "COUPLE" ? (
          <Routes>
            <Route path="/overview" element={<Overview report={report} setReport={setReport} />} />
            <Route path="/tenant" element={<ReportDetails values={score} report={report} tenant={tenant} setReport={setReport} setValues={setScore} />} />
            <Route
              path="/couple"
              element={<ReportDetails values={coupleScore} report={coupleReport} tenant={coupleTenant} setReport={setCoupleReport} setValues={setCoupleScore} />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/tenant" element={<ReportDetails values={score} report={report} tenant={tenant} setReport={setReport} setValues={setScore} />} />
          </Routes>
        )}
      </section>
    </div>
  );
};

export default Index;
