import { useLocation, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-hot-toast";
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from "react-icons/io5";
import Header from "../components/Header";

const CoupleGuarantorForm = ({ values, setValues }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onUpdate = async (report) => {
    const data = report ? report : values;
    try {
      const res = await api.put(`/report/${data._id}`, data);
      if (!res.ok) throw res;
      setValues(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRedirection = (type) => {
    if (type === "SUMMARY") navigate("../summary");
    else if (type === "NEXT") navigate("./documents-selection");
    else {
      navigate("../couple/documents-selection");
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center px-32 py-6 gap-8">
      <div className="rounded bg-white border p-6">
        <Header status={values?.status} />
      </div>
      <Details values={values} setValues={setValues} onUpdate={onUpdate} />
      {location.state?.from === "SUMMARY" ? (
        <div className="w-full flex justify-center">
          <button onClick={() => handleRedirection("SUMMARY")} className="primary-button flex items-center gap-2">
            <p>Valider et retourner le sommaire</p>
            <IoArrowForwardCircleOutline className="w-6 h-6" />
          </button>
        </div>
      ) : (
        <div className="w-full flex justify-between">
          <button onClick={() => handleRedirection("BACK")} className="secondary-button flex items-center gap-2">
            <IoArrowBackCircleOutline className="w-6 h-6" />
            <p>Retour</p>
          </button>
          <button onClick={() => handleRedirection("NEXT")} className="primary-button flex items-center gap-2">
            <p>Suivant</p>
            <IoArrowForwardCircleOutline className="w-6 h-6" />
          </button>
        </div>
      )}
    </div>
  );
};

const Details = ({ values, setValues, onUpdate }) => {
  return (
    <div className="w-full flex flex-col gap-4">
      <h1 className="text-lg font-semibold w-full px-4 py-2 bg-pink-600 text-white rounded">
        Détails du garant de votre conjoint.e : {values.guarantor_first_name} {values.guarantor_last_name}
      </h1>
      <div className="grid grid-cols-2 gap-x-2 gap-y-5 rounded bg-white border p-4">
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="first_name">
            Prénom
          </label>
          <input
            type="text"
            name="first_name"
            className="input"
            value={values?.guarantor_first_name}
            onChange={(e) => setValues({ ...values, guarantor_first_name: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez le prénom de votre garant"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="last_name">
            Nom
          </label>
          <input
            type="text"
            name="last_name"
            className="input"
            value={values?.guarantor_last_name}
            onChange={(e) => setValues({ ...values, guarantor_last_name: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez le nom de votre garant"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Adresse e-mail
          </label>
          <input
            type="email"
            name="email"
            className="input"
            value={values?.guarantor_email}
            onChange={(e) => setValues({ ...values, guarantor_email: e.target.value })}
            onBlur={() => onUpdate()}
            placeholder="Entrez votre adresse e-mail"
          />
        </div>
        <div className="space-y-2">
          <label className="text-base font-medium" htmlFor="email">
            Numéro de téléphone
          </label>
          <div className="flex items-center gap-3">
            +33
            <input
              type="input"
              className="input"
              max={10}
              value={values?.guarantor_phone}
              onChange={(e) => setValues({ ...values, guarantor_phone: e.target.value.slice(0, 9) })}
              onBlur={() => onUpdate()}
              placeholder="6XXXXXXXX"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoupleGuarantorForm;
