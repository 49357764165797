import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";

import api from "@/services/api";
import Loader from "@/components/Loader";
import Select from "@/components/Select";

import { MdOutlineArrowBack } from "react-icons/md";
import { TENANT_URL } from "../../../services/config";

const Index = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    reference: "",
    address: "",
    company_id: "",
    company_name: "",
    price: "",
    status: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/property/${id}`);
        if (!res.ok) throw res;
        setValues(res.data);
      } catch (e) {
        console.error(e);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const res = await api.put(`/property/${values._id}`, { ...values });
      if (!res.ok) throw res;
      toast.success("Location modifiée");
      setValues(res.data);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${TENANT_URL}/new?property=${values._id}`);
    toast.success("Lien copié");
  };

  if (loading) return <Loader />;

  return (
    <div className="p-8">
      <div className="w-full flex justify-start items-center mb-6">
        <Link to={`/dashboard/properties`} rel="noreferrer" className="secondary-button font-semibold">
          <MdOutlineArrowBack className="mr-2" /> Retour
        </Link>
      </div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <h1 className="text-3xl text-primary font-bold">
            {values.reference} - {values.address}
          </h1>
        </div>
      </div>
      <div className="rounded bg-white border p-6">
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label htmlFor="address" className="text-sm font-semibold">
              Adresse du bien
            </label>
            <input type="text" id="name" className="input" value={values.address} onChange={(e) => setValues({ ...values, address: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="reference" className="text-sm font-semibold">
              Numéro de référence
            </label>
            <input type="text" id="name" className="input" value={values.reference} onChange={(e) => setValues({ ...values, reference: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="manager" className="text-sm font-semibold">
              Responsable de la location
            </label>
            <input type="text" id="name" className="input" value={values.manager} onChange={(e) => setValues({ ...values, manager: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="price" className="text-sm font-semibold">
              Loyer mensuel
            </label>
            <input type="number" id="name" className="input" value={values.price} onChange={(e) => setValues({ ...values, price: e.target.value })} />
          </div>
          <div className="space-y-2">
            <label htmlFor="email" className="text-sm font-semibold">
              État du bien
            </label>
            <Select
              options={["AVAILABLE", "PENDING", "RENTED"]}
              value={values.status}
              onChange={(value) => setValues({ ...values, status: value })}
              getLabel={(status) => STATUS_SPAN[status]}
            />
          </div>
        </div>
        <div className="mt-8 flex justify-start gap-4">
          <button className="primary-button rounded-md px-6 py-2" onClick={handleSave}>
            {loading ? "Chargement..." : "Sauvegarder"}
          </button>
          <button className="primary-button" onClick={handleCopyLink}>
            Copier le lien de dépôt de dossier
          </button>
        </div>
      </div>
    </div>
  );
};

const STATUS_SPAN = {
  AVAILABLE: <span className="rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">Disponible</span>,
  PENDING: <span className="rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">En attente</span>,
  RENTED: <span className="rounded-md bg-violet-100 px-3 py-1 text-xs font-medium text-violet-600 border border-violet-600">Loué</span>,
};
export default Index;
