import Table from "./Table";
import Pagination from "./Pagination";

const TablePagination = ({ page, onPageChange, total, pageSize = 10, children, ...props }) => {
  return (
    <>
      <Table total={total} {...props}>
        {children}
      </Table>
      <div className="w-full flex justify-center border border-t-0 rounded-b-lg bg-white p-1">
        <Pagination page={page} setPage={onPageChange} last={parseInt(total / pageSize) || 1} />
      </div>
    </>
  );
};

export default TablePagination;
