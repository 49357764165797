import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from "./Loader";

const Table = ({ header, sort, total, onSort, loading, children, fixed = false, sticky = false, height = "h-full", width = null }) => {
  if (total === 0 && !loading) return <div className="w-full">No data found</div>;
  return (
    <div className={`w-full border rounded-b-none rounded overflow-x-auto ${height}`}>
      <table className={`w-full ${fixed ? "table-fixed" : ""}`}>
        <thead className={`text-left bg-white ${sticky ? "sticky top-0 z-10 shadow-md" : ""}`}>
          <tr className="border-b">
            {header.map((item, index) => (
              <th
                key={index}
                className={`p-2 ${item.key && "hover:bg-neutral-100 cursor-pointer"} ${item.width ? item.width : ""}`}
                colSpan={item.colSpan || 1}
                onClick={() => {
                  item.key && onSort(item.key);
                }}
              >
                <div
                  className={`flex items-center
                   ${
                     item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : item.position === "between" ? "justify-between" : "justify-start"
                   }`}
                >
                  <h3 className="text-sm font-semibold">{item.title}</h3>
                  {item.key && sort && (
                    <button className="flex flex-col justify-center">
                      {sort[item.key] === 1 ? <IoIosArrowUp /> : sort[item.key] === -1 ? <IoIosArrowDown /> : <IoIosArrowDown className="opacity-0" />}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative">
          {loading ? (
            <>
              <tr className="text-center cursor-pointer bg-neutral-50">
                {header.map((_) => (
                  <td className="py-4 px-3">
                    <div className="h-4 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
              <tr className="text-center cursor-pointer bg-white">
                {header.map((_) => (
                  <td className="py-4 px-3">
                    <div className="h-4 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
              <tr className="text-center cursor-pointer bg-neutral-50">
                {header.map((_) => (
                  <td className="py-4 px-3">
                    <div className="h-4 bg-gray-200 rounded w-full" />
                  </td>
                ))}
              </tr>
            </>
          ) : total === 0 ? (
            <tr>
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)} className="py-4 text-center bg-white bg-opacity-90">
                Aucune donnée trouvée
              </td>
            </tr>
          ) : (
            children
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
