import Loader from "../../../components/Loader";
import api from "../../../services/api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import Document from "../components/Document";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import { IoArrowBackCircleOutline, IoArrowForwardCircleOutline } from "react-icons/io5";
import { FaArrowLeft } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

const DOCUMENT_TYPES = ["ID", "TAX_NOTICE", "INCOME", "RENT_RECEIPT", "OTHER"];

const DOCUMENT_TITLE = {
  ID: "Document d'identité",
  TAX_NOTICE: "Avis d'imposition",
  INCOME: "3 derniers bulletins de salaire",
  RENT_RECEIPT: "Justificatif d'hébergement",
  OTHER: "Autre",
};

const TenantDocuments = ({ values, coupleValues, setValues }) => {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (values.document_ids) fetchData();
  }, [values.document_ids]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await api.post("/document/search", { documents: values.document_ids });
      if (res.ok) {
        setDocuments(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setDocuments(e);
    onUpdate({ ...values, document_ids: e.map((doc) => doc._id) });
  };

  const onUpdate = async (report) => {
    try {
      const res = await api.put(`/report/${values._id}`, report ? report : values);
      if (!res.ok) throw res;
      setValues(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRedirection = (type) => {
    if (type === "NEXT") navigate("../summary");
    else {
      if (values.has_guarantor) navigate("../tenant/guarantor/documents");
      else if (values.situation === "COUPLE") navigate("../couple/documents");
      else navigate("../tenant");
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center px-32 py-6 gap-8">
      <div className="rounded bg-white border p-6">
        <Header status={values?.status} />
      </div>
      <div className="w-full flex flex-col gap-4">
        <h1 className="text-lg font-semibold w-full px-4 py-2 bg-primary text-white rounded">
          Documents du locataire : {values.user_first_name} {values.user_last_name}
        </h1>
        {DOCUMENT_TYPES.map((type, index) => (
          <Document
            key={index}
            title={DOCUMENT_TITLE[type]}
            type={type}
            person={"TENANT"}
            report={values}
            required={type === "OTHER" ? "NONE" : values.has_guarantor ? (type === "ID" ? "MANDATORY" : "OPTIONAL") : "MANDATORY"}
            values={documents}
            onChange={(e) => handleChange(e)}
          />
        ))}
      </div>
      {location.state?.from === "SUMMARY" ? (
        <div className="w-full flex justify-center">
          <button onClick={() => handleRedirection("NEXT")} className="primary-button flex items-center gap-2">
            <p>Valider et retourner le sommaire</p>
            <IoArrowForwardCircleOutline className="w-6 h-6" />
          </button>
        </div>
      ) : (
        <div className="w-full flex justify-between">
          <button onClick={() => handleRedirection("BACK")} className="secondary-button flex items-center gap-2">
            <IoArrowBackCircleOutline className="w-6 h-6" />
            <p>Retour</p>
          </button>
          <button onClick={() => handleRedirection("NEXT")} className="primary-button flex items-center gap-2">
            <p>Suivant</p>
            <IoArrowForwardCircleOutline className="w-6 h-6" />
          </button>
        </div>
      )}
    </div>
  );
};

export default TenantDocuments;
