import React, { useState, useEffect, useRef } from "react";
import { IoCheckmarkCircle, IoCheckmarkCircleOutline } from "react-icons/io5";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";

function MultiSelect({ id, options, width, values, onSelectedChange, placeholder = "Select an option", naceCode }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (values) {
      setSelectedOptions(values);
    }
  }, [values]);

  function handleOptionClick(option) {
    let _selectedOptions;
    if (selectedOptions.find((_option) => _option.value === option.value)) {
      _selectedOptions = selectedOptions.filter((_option) => _option.value !== option.value);
    } else {
      _selectedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(_selectedOptions);
    onSelectedChange(_selectedOptions);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={`relative ${width && width}`}>
      <button
        id={id}
        type="button"
        onClick={() => {
          setIsOpen(isOpen ? false : true);
        }}
        className={`${
          width && width
        } min-w-[180px] inline-flex justify-between items-center px-4 py-3 border border-secondary text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50`}
      >
        <span id={`${id}-text`} className="flex-1 text-left">
          {selectedOptions.length === 0 ? placeholder : `${placeholder} (${selectedOptions.length})`}
        </span>
        {isOpen ? <HiChevronUp /> : <HiChevronDown />}
      </button>
      {isOpen && (
        <div ref={ref} className={`absolute z-10 mt-1 max-h-96 w-full bg-white shadow-lg rounded-md overflow-y-scroll`}>
          <ul className="border border-gray-200 divide-y divide-gray-200 list-none w-full">
            {options.map((option, index) => {
              const isSelected = selectedOptions.find((_option) => _option.value === option.value);
              return (
                <>
                  <li key={option} onClick={() => handleOptionClick(option)} className="w-full flex items-center gap-1 hover:bg-gray-100 text-sm  px-4 py-3 cursor-pointer">
                    {isSelected ? <IoCheckmarkCircle className="text-primary w-4 h-4" /> : <IoCheckmarkCircleOutline className="text-primary w-4 h-4" />}
                    <span className={`flex-1 text-primary ${isSelected ? "font-semibold" : "font-normal"}`}>{option.label}</span>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
